import { DatSection } from '@achieve/cx-debt-assessment'
import Section from 'components/Section/Section'
import styles from './DebtAssessmentSection.module.scss'
import { TrackingWrapper } from 'components/Tracking/TrackingWrapper'
import { useRef, useEffect, useState } from 'react'
import { AchieveTheme as theme, Box, Skeleton } from '@achieve/ascend'

import { useTrackLoadEvent } from 'hooks/useTrackLoadEvent'
import { useMemoizedContentGetter } from 'utils/contentful'
import { TitleDivider } from 'components/TitleDivider'
import { useRouter } from 'next/router'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { replaceUrlMpLXExperiment } from 'utils/shared'

const DEFAULT_SELECTED_VALUE = 0


/**
 * Calculates the monthly savings based on current assessment and default offers.
 *
 * @param {Object} obj - The object containing the necessary data for calculation.
 * @returns {string} The formatted monthly savings as a string prefixed with a dollar sign.
 */
const calculateMonthlySavings = (obj) => {
  const monthlyCurrent = Math.round(obj?.current_assessment?.cfos?.payoff_plans?.[0]?.tradeline_payoff?.[0]?.monthly_payment);
  const monthlyAchieve = Math.round(obj?.default_offers?.ar_offers?.[1]?.monthly_payment);
  const monthlySavings = Intl.NumberFormat('en-US').format(parseInt(monthlyCurrent) - parseInt(monthlyAchieve));
  return `$${monthlySavings}`;
}

function DebtAssessmentSection({ content }) {
  const trackRefCta = useRef()
  const trackRefLink = useRef()
  const trackRefSelect = useRef()
  const { eyebrow, title } = useMemoizedContentGetter(content, ['eyebrow', 'title'])
  const { query, pathname } = useRouter()
  const enableMpLXExperiment = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_DR_MP_VS_LX)

  const appendQueryParams = (url, query) => {
    const existingParams = url.includes('?') ? url.split('?')[1] : ''
    const currentParams = new URLSearchParams(existingParams)
    const additionalParams = new URLSearchParams(query)

    for (const [key, value] of additionalParams) {
      currentParams.set(key, value)
    }

    const baseUrl = url.split('?')[0]
    const updatedQuery = currentParams.toString()
    return updatedQuery ? `${baseUrl}?${updatedQuery}` : baseUrl
  }

  const [formSelectedValue, setFormSelectedValue] = useState(DEFAULT_SELECTED_VALUE)
  const [loading, setLoading] = useState(true)
  const [dynamicTitle, setDynamicTitle] = useState(content?.fields?.additionalConfiguration?.jsonContent?.dynamicTitle?.replace('{ACX-monthly-savings}', content?.fields?.additionalConfiguration?.jsonContent?.tableProps?.monthlySavings) || '')
  const [loanAmount, setLoanAmount] = useState(
    content?.fields?.additionalConfiguration?.jsonContent?.defaultSelectedValue || 0
  )

  let additionalConfiguration = content?.fields?.additionalConfiguration?.jsonContent
    ? {
        ...content.fields.additionalConfiguration.jsonContent,
        defaultSelectedValue: Number(
          content?.fields?.additionalConfiguration?.jsonContent?.defaultSelectedValue
        ),
        CtaUrl: `${content?.fields?.additionalConfiguration?.jsonContent?.CtaUrl}&loan_amount=${loanAmount}`,
      }
    : {}
  if (additionalConfiguration?.redirectWithQueryParams) {
    additionalConfiguration = {
      ...additionalConfiguration,
      CtaUrl: appendQueryParams(additionalConfiguration.CtaUrl, query),
      datWaitProps: {
        ...additionalConfiguration.datWaitProps,
        linkHref: appendQueryParams(additionalConfiguration.datWaitProps.linkHref, query),
      },
    }
  }

  // ACX_WEB_DR_MP_VS_LX experiment
  if (enableMpLXExperiment?.toLowerCase() === 'variation_a') {
    additionalConfiguration = {
      ...additionalConfiguration,
      CtaUrlOrg: additionalConfiguration.CtaUrl,
      CtaUrl: replaceUrlMpLXExperiment(additionalConfiguration.CtaUrl, pathname),
      datWaitProps: {
        ...additionalConfiguration.datWaitProps,
        linkHref: replaceUrlMpLXExperiment(additionalConfiguration.datWaitProps.linkHref, pathname),
      },
    }
  }

  const args = {
    ...additionalConfiguration,
    onCtaClick: function () {
      trackRefCta?.current?.click()
    },
    onClick: function () {
      trackRefLink?.current?.click()
    },
    fireEvent: function (value) {
      setFormSelectedValue(value)
      setLoanAmount(value)
    },
    onResponse: function(response) {
      setDynamicTitle(content?.fields?.additionalConfiguration?.jsonContent?.dynamicTitle?.replace('{ACX-monthly-savings}', calculateMonthlySavings(response)))
    }
  }

  const backgroundColorMap = {
    white: theme.sb?.colors?.neutral?.white,
    lightGray: theme.sb?.colors?.neutral?.grey?.[8],
    lightBlue: theme.sb?.colors?.primary?.highlightBlue,
  }

  useTrackLoadEvent({
    event_action: 'app_event',
    form_ss_amount: `${
      additionalConfiguration?.defaultSelectedValue ??
      additionalConfiguration?.selectItemList?.[0]?.value
    }`,
    track_event: 'tool_render',
  })

  // useEffect to handle changes from the select field
  useEffect(() => {
    if (formSelectedValue) {
      // Trigger event to track callback event
      trackRefSelect?.current?.click()
    }
    // Defaults to loading, stop it once an initial value is received
    loading && setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSelectedValue])

  return (
    <Section
      backgroundColor={
        backgroundColorMap[additionalConfiguration?.backgroundColor] ?? backgroundColorMap.white
      }
      className={styles['container']}
    >
      <Box className={styles['box-form']}>
        <TitleDivider eyebrowBlack={true} eyebrow={eyebrow} title={ content?.fields?.additionalConfiguration?.jsonContent?.dynamicTitle ? dynamicTitle : title } />
        <TrackingWrapper
          data-testid="dat-wait-link-click"
          track={{
            list_name: 'DAT WAIT LINK',
            click_id: additionalConfiguration?.datWaitProps?.linkText,
            click_text: `Achieve-Web | ${additionalConfiguration?.datWaitProps?.linkText}`,
            click_url: additionalConfiguration?.datWaitProps?.linkHref,
            click_type: 'Link Click',
            event_action: 'link_click',
            nav_link_section: 'Tool If You Wait',
            track_event: 'internal_campaign_click',
          }}
        >
          <div ref={trackRefLink} />
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dat-button-click"
          track={{
            list_name: 'DAT BUTTON',
            click_id: additionalConfiguration?.CtaCopy,
            click_text: `Achieve-Web | ${additionalConfiguration?.CtaCopy}`,
            click_url: additionalConfiguration?.CtaUrlOrg,
            click_type: 'Button Click',
            event_action: 'button_click',
            nav_link_section: 'Tool CTA',
            track_event: 'internal_campaign_click',
          }}
        >
          <div ref={trackRefCta} />
        </TrackingWrapper>
        <TrackingWrapper
          data-testid="dat-select-field"
          track={{
            list_name: 'DAT SELECT FIELD',
            click_id: formSelectedValue,
            click_text: `Achieve-Web | ${formSelectedValue}`,
            form_ss_amount: formSelectedValue,
            click_type: 'UI Click',
            event_action: 'ui_click',
            nav_link_section: 'Tool Form',
            track_event: 'ui_click',
          }}
        >
          <div ref={trackRefSelect} />
        </TrackingWrapper>
        {loading ? (
          <Skeleton variant="rectangular">
            <DatSection {...args} />
          </Skeleton>
        ) : (
          <DatSection {...args} />
        )}
      </Box>
    </Section>
  )
}
export { DebtAssessmentSection }
export default DebtAssessmentSection
